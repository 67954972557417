
import { Component } from 'vue-property-decorator';
import ExportWidget from '@/components/shared/ExportWidget.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import { Venue } from '@/interfaces/models/Venue';
import { namespace } from 'vuex-class';
import { toUrl } from '@/util/helper';
import moment from 'moment';
import { ExportType } from '@/enums/ExportType';
import StackedForm from '@/mixins/StackedForm';
import CustomerGroup from '@/mixins/CustomerGroup';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import ExportService from '@/api/http/ExportService';

const app = namespace('app');

interface ExportFormData {
  type: string;
  venue: string;
  format: string;
  from: string;
  to: string;
}

@Component({
  components: { ExportWidget, VWrapper, VFormBuilder },
})
export default class ActivityLogExport extends mixins(Notification, StackedForm, CustomerGroup) {
  @app.State('venues') public venues!: Venue[];

  public $refs!: {
    typeForm: InstanceType<typeof VFormBuilder> & { form: { type: string } };
    venueForm: InstanceType<typeof VFormBuilder> & { form: { venue: string } };
    typeExportFile: InstanceType<typeof VFormBuilder> & { form: { typeExportFile: string } };
    dateForm: InstanceType<typeof VFormBuilder> & { form: { range: { from: string; to: string } } };
  };

  public exportTypes = ExportType;
  public exportCustomerCsv(data: ExportFormData) {}

  get typeItems() {
    return [
      {
        name: 'type',
        type: InputType.Select,
        label: 'activityLogExport.form.type',
        items: this.typeExport,
        multiple: false,
        rules: 'required',
      },
    ];
  }
  get venueItems() {
    return [
      {
        name: 'venue',
        type: InputType.Autocomplete,
        label: 'activityLogExport.form.venue',
        items: this.venues,
        itemValue: '_id',
        itemText: 'name',
        multiple: false,
        rules: 'required',
      },
    ];
  }

  get dateItems() {
    return [
      {
        name: 'range',
        type: InputType.DateRange,
        label: 'activityLogExport.form.range',
      },
    ];
  }

  get typeExportItems() {
    return [
      {
        name: 'typeExportFile',
        type: InputType.Select,
        label: 'activityLogExport.form.typeExportFile',
        items: this.typeFile,
        rules: 'required',
      },
    ];
  }

  get typeFile() {
    const items = [
      { text: 'Csv', value: ExportType.CSV },
      { text: 'Pdf', value: ExportType.PDF },
      { text: 'Xlsx', value: ExportType.XLSX },
    ];

    return items;
  }

  get typeExport() {
    return [
      { text: 'ArticleCategory', value: 'ArticleCategory' },
      { text: 'Article', value: 'Article' },
    ];
  }

  public getTypeExportFile(form: any) {
    if (form && form.form && form.form.typeExportFile) {
      return { format: form.form.typeExportFile };
    }
    return {};
  }

  public doExport() {
    this.validate().then((res: boolean[]) => {
      if (this.isValid(res)) {
        this.$startLoading('exportActivityLog');
        const api: ExportService = new ExportService();
        const data: ExportFormData = this.getData();
        api
          .exportActivityLog(data)
          .then((res: any) => {
            toUrl(res.data, `activity-log-${moment().format('DD-MM-YYYY')}.${data.format}`);
          })
          .finally(() => {
            this.$stopLoading('exportActivityLog');
          });
      }
    });
  }

  public getData() {
    return {
      venue: this.$refs.venueForm && this.$refs.venueForm.form ? this.$refs.venueForm.form.venue : null,
      type: this.$refs.typeForm && this.$refs.typeForm.form ? this.$refs.typeForm.form.type : null,
      ...(this.$refs.dateForm && this.$refs.dateForm.form ? this.$refs.dateForm.form.range : null),
      ...this.getTypeExportFile(this.$refs.typeExportFile),
    };
  }
}
