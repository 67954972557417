
import { Component } from 'vue-property-decorator';
import ExportWidget from '@/components/shared/ExportWidget.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import { Venue } from '@/interfaces/models/Venue';
import { namespace } from 'vuex-class';
import { OrderFlavor } from '@/enums/OrderFlovor';
import CustomerApiService from '@/api/http/CustomerApiService';
import { toUrl } from '@/util/helper';
import moment from 'moment';

const app = namespace('app');
const download = namespace('download');

interface ExportFormData {
  venue: string;
  flavor: OrderFlavor;
  from: string;
  to: string;
  lang?: string;
  labels?: string[];
  withoutTags?: boolean;
  subscribedToMailing?: boolean;
}

@Component({
  components: { ExportWidget, VWrapper },
})
export default class UserExport extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];

  public exportCustomerCsv(data: ExportFormData) {
    this.$startLoading('customerCsv');
    const api: CustomerApiService = new CustomerApiService();
    api
      .customerCsv({ ...data })
      .then((res: any) => toUrl(res.data, `customer-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('customerCsv');
      });
  }

  public exportCustomerSubscribedToMailingCsv(data: ExportFormData) {
    this.$startLoading('customerSubscribedToMailing');
    const api: CustomerApiService = new CustomerApiService();
    api
      .customerCsv({ ...data })
      .then((res: any) => toUrl(res.data, `customer-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('customerSubscribedToMailing');
      });
  }
}
